.container {
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border: 1px solid gray;
  border-radius: 7px;
  padding: 3px;
}

.text {
  color: white;
  font-size: 15px;
  text-shadow: 0px 0px 8px black;
  text-align: center;
}
