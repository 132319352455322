.container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.icon {
  width: 50px;
  height: auto;
  color: white;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.25);
}
