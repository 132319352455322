.container {
  position: absolute;

  inset: 0;

  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;

  background-color: rgba(0.1, 0.1, 0.1, 0.5);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 80px;
  width: 80px;

  border-radius: 100%;
  border: 15px solid;
  border-color: lightgrey transparent lightgrey lightgrey;

  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
