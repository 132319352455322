.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailsContainer {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0px;
}
