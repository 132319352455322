.box {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 4px;
  border: solid gray 2px;
  cursor: pointer;
}

.checked {
  border-color: #ff3c3c;
}

.leftBox {
  position: absolute;
  width: 8px;
  height: 4px;
  left: 1px;
  top: 7px;
  transform: rotate(45deg);
  background-color: #ff3c3c;
}

.rightBox {
  position: absolute;
  width: 12px;
  height: 4px;
  right: 0px;
  top: 6px;
  transform: rotate(-45deg);
  background-color: #ff3c3c;
}
