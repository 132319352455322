html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: black;
  overscroll-behavior-y: none;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#root {
  height: 100%;
}

p {
  margin: 0;
}

canvas {
  display: block;
}

* {
  box-sizing: border-box;
}
