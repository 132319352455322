.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  position: relative;
  background-color: lightgray;
  border: 4px solid black;
}

.closeButton {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 5px;
  cursor: pointer;
}
