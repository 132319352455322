.button {
  min-width: 150px;
  border-radius: 8px;
  padding: 10px 20px;

  font-size: 16px;

  border: solid #ff3c3c 2px;
  background-color: #d12c2c;

  color: white;
  cursor: pointer;
}

.button:hover {
  border: solid black 2px;
  background-color: #220303;
}

.button:disabled {
  border: solid #681818 2px;
  background-color: gray;
}

.abort {
  border: solid #a32424 2px;
  background-color: #871d1d;
}
