.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
