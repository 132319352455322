@keyframes x-movement {
  from {
    background-position-x: 0px;
  }
  50% {
    background-position-x: 100px;
  }
  to {
    background-position-x: 0px;
  }
}

@keyframes y-movement {
  from {
    background-position-y: 0px;
  }
  50% {
    background-position-y: 100px;
  }
  to {
    background-position-y: 0;
  }
}

.container {
  background-image: url(/public/images/background-tile.png);
  width: 100%;
  height: 100%;

  animation:
    y-movement 20s infinite,
    x-movement 12s infinite;
}

.wrapper {
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);

  overflow: auto;
}
