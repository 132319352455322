.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputsContainer {
}

.section {
  margin-bottom: 10px;
}

.buttonsSection {
  margin-top: 25px;
  text-align: center;

  display: flex;
  gap: 20px;
}

.checkboxSection {
  padding-left: 10px;
  margin-bottom: 10px;
}

.nameLabel {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 4px;
  padding-right: 10px;
}

.label {
  color: white;
  font-size: 20px;
  margin-bottom: 4px;
  padding-right: 10px;
}
