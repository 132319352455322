.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headSection {
  display: flex;
  align-items: center;
  gap: 30px;
}

.name {
  color: white;
  font-size: 30px;
  text-shadow: 0px 0px 8px black;
  font-weight: bold;
}

.detailSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.detailLabel {
  color: white;
  font-size: 20px;
  width: 50%;
  text-align: right;
  text-shadow: 0px 0px 8px black;
}

.detailValue {
  color: white;
  font-size: 20px;
  width: 50%;
  text-shadow: 0px 0px 8px black;
}

.singleDetail {
  color: white;
  font-size: 20px;
  text-shadow: 0px 0px 8px black;
}

.subsectionTitle {
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 0px 0px 8px black;
}

.playersContainer {
  width: 300px;
  height: 25px;
  overflow: visible;
}
