.title {
  margin: 0 5px;
  max-width: 100%;

  object-fit: contain;

  width: 364px;
  height: 364px;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.buttonGroupWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.modalTitle {
  font-weight: bold;
  font-size: 40px;
}

.modalDescription {
  margin-bottom: 30px;
}

.modalButtonWrapper {
  display: flex;
  gap: 20px;
}
