.buttonGroupWrapper {
  display: flex;
  gap: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.backButtonWrapper {
  margin-top: 30px;
}

.title {
  font-size: 50px;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  margin-top: 0;
}
