.buttonGroupWrapper {
  display: flex;
  gap: 10px;
}

.backButtonWrapper {
  margin-top: 30px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.settingsSection {
  margin-bottom: 30px;
}

.inputWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.label {
  color: white;
  font-size: 20px;
  text-shadow: 0px 0px 4px black;
}
