.container {
  width: 100%;
  background-color: gray;
  padding: 3px 10px;

  display: flex;
  justify-content: space-between;

  cursor: pointer;
}

.container:hover {
  background-color: darkgray;
}

.name {
  color: white;
  font-size: 20px;
  text-shadow: 0px 0px 4px black;
}

.details {
  color: white;
  font-size: 20px;
  text-shadow: 0px 0px 4px black;
}
