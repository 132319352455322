.title {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}

.settingsSection {
  margin-bottom: 30px;
}

.inputWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.label {
  color: white;
  font-size: 20px;
  text-shadow: 0px 0px 4px black;
}

.statsGrid {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 20px;
  max-width: 1000px;
  padding: 0 50px;
}

.statLabel {
  color: white;
  padding: 5px;
  border: 2px solid black;
}

.statValue {
  color: white;
  padding: 5px;
  border: 2px solid black;
}
