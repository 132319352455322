.textInput {
  padding: 10px;
  border-radius: 8px;
  border: solid gray 2px;
}

.textInput:focus {
  outline: none;
  border: solid #ff3c3c 2px;
}
