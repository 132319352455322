.title {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.description {
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
  width: 70%;
  text-align: center;
}

.buttonWrapper {
  margin-bottom: 10px;
}
